





























import { tasksStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'TaskStatusSelector' })
export default class TaskStatusSelector extends Vue {
  @Prop({
    type: String,
  }) readonly value!: string

  // bodgy bodge for our z-index values vs quasar default ones
  popupContentStyle: Partial<CSSStyleDeclaration> = { zIndex: '9999' }

  tasksStatusTitleByUid (uid: string): string | null {
    return tasksStore.getters.statusTitleByUid(uid)
  }

  get tasksStatuses () {
    return tasksStore.state.statuses
  }
}
